import React from "react"
// import ReactDOM from "react-dom"
import { Bar } from "react-chartjs-2"
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js"


Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
)
function PropBar(props) {
  const datas = props.activity
  let sche = []
  let saves = []

  if(datas.value !== undefined){
    datas.value.forEach(obj => {
      sche.push(obj.scheduled_tour)
      saves.push(obj.saves)
    })
  }

          const data = {
            labels:  datas.durartion,
            datasets: [
          {
            label: "Saved",
            backgroundColor: "#2d414e",
            data: saves,
            barPercentage: 0.9,
            barThickness: 10,
            bordercolor: "rgb(242, 242, 242)",
            maxBarThickness: 5,
            minBarLength: 2,
          },

          {
            label: "Scheduled Tours",
            backgroundColor: "#178be4",

            data: sche,
            barPercentage: 0.9,
            barThickness: 10,

            bordercolor: "rgb(242, 242, 242)",
            maxBarThickness: 5,
            minBarLength: 2,
          },
        ],
      }
   
     const options = {
      responsive: true,
      scales: {
        x: {
          beginAtZero: true,
          drawTicks: false,
          drawOnchartArea: false,
          label: {
            display: true,
          },
          grid: {
            color: "green",
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        },
        y: {
          beginAtZero: true,
          display: false,
          grid: {
            // color: "green",
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            pointStyle: "circle",
            padding: 30,
          },
          display: true,
          position: "bottom",
          align: "start",
        },
      },
    }
    return (
      <Bar
        data={data}
        width={"100%"}
        height={"100px"}
        options={options}
      />
    )
  }

export default PropBar
